body {
	background-color: #282c34;
}

.front-page {
	text-align: center;
}

.container {
	min-height: 93vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.intro {
	margin-bottom: 20px;
	.spooky-boy {
		font-size: 100px;
	}

	p {
		margin-bottom: 0;
	}

	.subtext {
		font-size: 11px;
		margin-top: 0px;
	}
}

.app-link {
	color: #61dafb;
}

.death-form {
	label {
		font-size: 16px;
		margin-right: 5px;
	}

	.submit-btn {
		margin-top: 40px;
		padding: 9px 18px;
		font-size: 15px;
		font-weight: bold;
		color: white;
		background-color: #394f00;
		border: none;
		cursor: pointer;
	}
}

.death-results {
	p {
		margin-top: 0px;
		margin-bottom: 0px;
		font-size: 16px;
	}

	.go-back {
		text-decoration: underline;
		cursor: pointer;
		color: white;
		margin-top: 50px;
		font-size: 14px;
		display: block;
	}

	.data-source {
		margin-top: 70px;
		font-size: 12px;
		a {
			color: white;
		}
	}

	.death-percentage {
		font-size: 25px;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}

footer {
	color: white;
	font-size: 14px;

	p {
		margin-bottom: 10px;
	}

	a {
		color: white;
	}
}

.chart-container {
	background-color: #adadad;
	padding: 15px;
	width: 85vw;
	margin: 0 auto;

	h3 {
		margin-top: 0;
		text-align: left;
		color: #282c34;
	}
}
